<template>
  <div>
    <loading v-if="showLoading"></loading>
    <Header
      title="邀請紀錄"
      :showMemberCenter="false"
      :logo="meta.header_image"
    />
    <div class="wrapper">
      <div class="top">
        <div class="top__item">
          <div class="top__title">已成功邀請</div>
          <div class="top__num"><b>{{ successCount }}</b> /人</div>
        </div>
        <div class="top__item">
          <div class="top__title">己完成兌換</div>
          <div class="top__num"><b>1</b> /次</div>
        </div>
      </div>

      <div class="invite-list">
        <div class="invite-list__item" v-for="item in list" :key="item">
          <MgmProfileAvatar :pic_url="item.pic_url" />
          <div class="description">
            <div class="name">{{ item.name }}</div>
            <div class="date">{{ item.date }} 邀請</div>
          </div>

          <div class="status">
            <img v-if="item.isInvited" src="./images/stamp-success.png" />
            <img v-else src="./images/stamp-process.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/Page/Liff/Shared/Header";
import MgmProfileAvatar from "@/components/Page/Liff/Mgm/MgmProfileAvatar";
import mgmApi from "@/apis/liff/v2/mgm";
import { format } from "date-fns";

export default {
  components: {
    Header,
    MgmProfileAvatar,
  },
  data() {
    return {
      list: [],
      successCount: 0,
      showLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
    }),
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.showLoading = true
      // 拿MGM邀請資料
      const { data } = await mgmApi.getInviteList(this.$route.params.mgmId)

      this.setList(data.data.mgm_invites)

      this.showLoading = false
    },
    setList(inviteList) {
      for (var i = inviteList.length - 1; i >= 0; i--) {
        this.list.push({
          pic_url: inviteList[i].receiver.avatar_url,
          name: inviteList[i].receiver.name,
          date: format(new Date(inviteList[i].receiver_at), "yyyy/MM/dd HH:mm"),
          isInvited: inviteList[i].invited_success
        })
        if (inviteList[i].invited_success) {
          this.successCount += 1
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: calc(100vh - 56px);
  background: #fff;
  padding-bottom: 110px;
}

.top {
  padding: 20px 12px;
  border-bottom: 1px solid #e5e5ea;
  display: flex;
  &__item {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
  }
  &__title {
    font-size: 13px;
    line-height: 13px;
    color: #979797;
    margin-bottom: 4px;
  }
  &__num {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #2c2c2e;
    b {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #3973ac;
    }
  }
}

.invite-list {
  padding: 24px 12px;
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 12px;

      .name {
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        color: #2c2c2e;
        margin-bottom: 8px;
      }

      .date {
        font-size: 12px;
        line-height: 12px;
        color: #979797;
      }
    }
    .status {
      transform: rotate(-5deg) scale(0.9);
      margin-left: auto;
    }
  }
}
</style>
