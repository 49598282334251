<template>
  <div
    class="mgm-profile-avatar"
    :style="{
      background: 'url(' + pic_url + ') center / cover, #fff',
    }"
  >
    <svg
      v-if="!pic_url"
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44 46V43.3333C44 41.9188 43.4381 40.5623 42.4379 39.5621C41.4377 38.5619 40.0812 38 38.6667 38H29.3333C27.9188 38 26.5623 38.5619 25.5621 39.5621C24.5619 40.5623 24 41.9188 24 43.3333V46"
        stroke="#6699CC"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M34.0003 32.6667C36.9458 32.6667 39.3337 30.2789 39.3337 27.3333C39.3337 24.3878 36.9458 22 34.0003 22C31.0548 22 28.667 24.3878 28.667 27.3333C28.667 30.2789 31.0548 32.6667 34.0003 32.6667Z"
        stroke="#6699CC"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M48.667 27.6666V35.6666"
        stroke="#6699CC"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M52.667 31.6666H44.667"
        stroke="#6699CC"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: ["pic_url"],
};
</script>

<style lang="scss" scoped>
.mgm-profile-avatar {
  border: 1px solid rgba(102, 153, 204, 0.6);
  border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
  width: 70px;
  height: 70px;
  border-radius: 50px;
}
</style>
