import https from "./https";
import store from "@/store";

const mgm = {
  show(mgmId) {
    const organization = store.state.liffGeneral.orgCode;

    return https.get(`${organization}/liff/mgm/${mgmId}`);
  },

  invite(mgmId, params) {
    const organization = store.state.liffGeneral.orgCode;
    return https.post(`${organization}/liff/mgm/${mgmId}/invite`, params);
  },

  getInviteList(mgmId) {
    const organization = store.state.liffGeneral.orgCode;
    return https.post(`${organization}/liff/mgm/${mgmId}/invite-list`);
  },
};

export default mgm;
